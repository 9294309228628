<template>
	<dialog ref="dialog" @close="emit('update:open', false)" @keyup.enter="() => handleAdd(selectedItems)">
		<form @submit.prevent>
			<input
				type="text"
				autofocus
				autocomplete="off"
				:placeholder="placeholder"
				@input="event => emit('pattern', event.target.value)"
			/>
			<button class="add" @click="() => handleAdd(selectedItems)">Add</button>
			<button class="close" @click="emit('update:open', false)">Close</button>
		</form>

		<span :class="{ invisible: !showErrorMessage }" class="error-message"> Select at least one {{ entityLabel }} </span>

		<ul class="results">
			<li v-for="item in results" :key="item[idKey]" :data-id="item[idKey]" class="result">
				<label :for="item[idKey]" class="details">
					<slot name="details" :item="item"></slot>
				</label>
				<input type="checkbox" :id="item[idKey]" v-model="selectedItems" :value="item" />
			</li>
		</ul>
	</dialog>
</template>

<script setup lang="ts">
import { ref, watch, toRaw } from "vue"

const props = defineProps<{
	results: Array<any>
	open: boolean
	placeholder?: string
	entityLabel?: string
	idKey?: string
}>()

const emit = defineEmits(["update:open", "pattern", "add"])

const selectedItems = ref([])
const showErrorMessage = ref(false)
const dialog = ref(null)

watch(
	() => props.open,
	open => {
		if (open) {
			dialog.value?.showModal()
		} else {
			selectedItems.value = []
			showErrorMessage.value = false
			dialog.value?.close()
		}
	}
)

function handleAdd(items = []) {
	if (items.length === 0) {
		showErrorMessage.value = true
	} else {
		emit("add", items.map(toRaw))
		emit("update:open", false)
	}
}

// Erase error message after 3 seconds
watch(showErrorMessage, show => {
	if (!show) {
		return
	}
	window.setTimeout(() => {
		showErrorMessage.value = !show
	}, 3_000)
})
</script>

<style scoped>
dialog {
	&:not([open]) {
		display: none;
	}

	/* center in screen */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	width: 1000px;
	height: 90vh;

	display: flex;
	flex-direction: column;
	gap: 10px;

	font-size: 150%;
	padding: 30px 50px;

	scrollbar-gutter: stable;

	form {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		font-size: 150%;

		input {
			flex: 1;
			padding: 0.2em;
		}
		button {
			width: 100px;
			user-select: none;

			&.add {
				background-color: var(--accent-color);
				color: white;
				font-weight: bold;
			}
			&.close {
				margin-left: 100px;
			}
		}
	}

	.error-message {
		display: flex;
		min-height: 25px;
		justify-content: center;
		font-size: 120%;
		font-weight: bold;
		color: var(--warning-color);
	}

	.results {
		list-style: none;

		display: flex;
		flex-direction: column;

		.result {
			display: flex;
			justify-content: space-between;

			&:nth-child(odd) {
				background-color: hsl(0deg 0% 95%);
			}
			&:hover {
				background-color: hsl(from var(--accent-color) h s l / 0.1);
			}

			.details {
				cursor: pointer;
			}

			input[type="checkbox"] {
				transform: scale(1.5);
				margin-right: 15px;
				outline: none;
			}
		}
	}
}
</style>
