import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import App from '@/App.vue'
import AppRouter from '@/AppRouter'
import i18n from '@/lang/i18n'
import { useMediaQuery } from '@vueuse/core'

import 'normalize.css'
import '@/assets/styles/main.css'

import { UseRootStore } from "./src/model/RootStore.ts"
import { GraphQLClient, TokenProvider } from "./src/GraphQLClient.ts"
import { ProjectRepository } from './src/repositories.ts'
import { createProjectStore } from './src/stores/projectStore.ts'

const app = createApp(App)

app.use(createPinia())
app.use(AppRouter)
app.use(i18n)

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const rootStore = UseRootStore()

class ApiTokenProvider implements TokenProvider {
    get(): string {
        return rootStore.userApiToken
    }
}

const apiTokenProvider = new ApiTokenProvider()

const apiUrl = new URL(import.meta.env.VITE_GRAPHQL_API_ENDPOINT)
const gqlClient = new GraphQLClient(apiTokenProvider, apiUrl)

const projectRepository = new ProjectRepository(gqlClient)
const useProjectStore = createProjectStore(rootStore, projectRepository)

app.provide('isLargeScreen', isLargeScreen)
app.provide('useProjectStore', useProjectStore)
app.provide('useRootStore', UseRootStore)

const environment = import.meta.env.MODE
const isDev = environment === 'development'

// Setup error tracking
Sentry.init({
	app,

    // https://github.com/getsentry/sentry-javascript/blob/8c2aa1b883785ab1ce5f7c8173907f5fcbf39912/packages/types/src/options.ts#L39-L40
	dsn: isDev ? undefined : 'https://123581839d5e4cfd925e916ed90aa68f@o733221.ingest.sentry.io/4505003190910976',

	integrations: [
		Sentry.browserTracingIntegration({ AppRouter }),
		Sentry.replayIntegration(),
        ],
	environment: environment,

	tracePropagationTargets: ['localhost', 'app-staging.landerz.ca', /^\//],

	// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Sets the sample rate to be 10% for full sessions
	replaysSessionSampleRate: 1,

	// Sets the sampel rate to be 100% for session with an error
	replaysOnErrorSampleRate: 1.0,
})

app.mount('#app')
