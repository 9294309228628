import { Project, validateProject } from "./model/DataModel"
import { Repository } from "./stores"

export type ApiToken = string

export interface Client {
	fetch<T>(query: string, vars: any[]): Promise<T | null>
}

export class ProjectRepository implements Repository<Project> {
	constructor(private readonly client: Client) {}

	async get(id: string): Promise<Project | null> {
		const query = `
            query Project($projectId: UUID!) {
                project(id: $projectId) {
                    id
                    createdAt
                    updatedAt
                    name
                    searchFilters
                    opportunities {
                        id
                        updatedAt
                        status
                        stage
                        comment
                    }
                }
            }
        `
		const response = await this.client.fetch<{ project: Project }>(query, { projectId: id })
		if (response.project === null) {
			return null
		}

		return validateProject(response.project)
	}
}
