import { Client } from "@/repositories"

export interface TokenProvider {
	get(): string
}

class ApiError extends Error {
	constructor(...messages: string[]) {
		super(messages.join("; "))

		this.name = this.constructor.name
		this.messages = messages

		Object.setPrototypeOf(this, new.target.prototype)
	}
}

function gqlErrorToString(error): string {
	return `[${error.extensions.code}] ${error.message}`
}

export class GraphQLClient implements Client {
	constructor(
		private readonly token: TokenProvider,
		private readonly endpoint: URL
	) {}

	// GraphQL Response formats
	//
	// Success
	//      {
	//          "data": { ... }
	//      }
	//
	// Error
	//      {
	//        "errors": [
	//          {
	//            "message": "Syntax Error: Unexpected Name \"FooBar\".",
	//            "locations": [
	//              {
	//                "line": 1,
	//                "column": 1
	//              }
	//            ]
	//          }
	//        ]
	//      }
	//
	// Type T is expected to describe the value of "data" in a success
	// response.
	//
	// Raises ApiError instance on error response and parsing error
	async fetch<T>(query: string, vars: any[]): Promise<T | null> {
		const response = await window.fetch(this.endpoint, {
			method: "POST",
			body: JSON.stringify({ query, variables: vars }),
			headers: {
				"Content-Type": "application/json",
				"x-api-token": this.token.get(), // TODO should be a bearer token!
			},
		})
		const body = await response.json()

		if (body.errors) {
			throw new ApiError(body.errors.map(gqlErrorToString))
		}
		if (body.data) {
			return body.data
		}

		throw new ApiError(`Unexpected API response: ${body}`)
	}
}
