import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { Project } from "@/model/DataModel.ts"
import { Repository } from "@/stores"

function getQueryParam(name: string): string | null {
	return new URLSearchParams(window.location.search).get(name)
}

export function createProjectStore(rootStore, repo: Repository) {
	return defineStore("project", () => {
		const loading = ref(false)
		const currentProject = ref<Project | null>(null)

		async function fetchCurrentProject(): Promise<void> {
			const id = getQueryParam("project")
			if (id === null) {
				return null
			}

			loading.value = true
			try {
				currentProject.value = await repo.get(id)
			} finally {
				loading.value = false
			}
		}

		return {
			loading,
			currentProject,
			fetchCurrentProject,
		}
	})
}
