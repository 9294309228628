import {
	LayerType,
	Listing,
	ListingSchema,
	Parcel,
	ParcelSchema,
	Prospect,
	ProspectSchema,
	DevelopmentPotential,
	Transaction,
	TransactionSchema,
	Municipality,
	MunicipalitySchema,
	TransactionOverrides,
	Contact,
	ContactList,
	CompanyList,
	ContactListSchema,
	CompanyListSchema,
} from "@/model/DataModel.ts"
import * as Sentry from "@sentry/browser"
import axios from "redaxios"

function urljoin(...parts: Array<string | URL>): URL {
	const sanitized = parts.map(p => {
		p = p.toString()
		p = p.trim()
		p = p.replace(/\/$/, "")
		p = p.replace(/^\//, "")

		return p
	})
	return new URL(sanitized.join("/"))
}

export function processListingData(listingItem: any): Listing | undefined {
	let newListing: Listing = {
		id: listingItem.id,
		hubspot_record_id: listingItem.hubspot_record_id,
		type: listingItem.listing_type,
		typeDevArray: listingItem.development_type || [],
		superficy: listingItem.analysis_land_area || 0,
		latitude: listingItem.latitude,
		longitude: listingItem.longitude,
		constructible: listingItem.analysis_buildable_area || 0,
		price: listingItem.listing_asking_price || 0,
		pricePerSqft: listingItem.listing_asking_price / listingItem.analysis_land_area,
		pricePerConstructibleSqft: listingItem.listing_asking_price / listingItem.analysis_buildable_area,
		link: listingItem.listing_webpage_url,
		address: listingItem.lot_address,
		city: listingItem.lot_city,
		state: listingItem.lot_state,
		postal_code: listingItem.lot_postal_code,
		image: listingItem.lot_image_url,
		off_market_radius: Number(listingItem.off_market_radius),
		sales_process_status: listingItem.sales_process_status,
		launch_date: undefined,
		launch_date_ms: undefined,
		call_for_bids: Boolean(listingItem.call_for_bids),
		broker_email: listingItem.broker_email,
		broker_first_name: listingItem.broker_first_name,
		broker_last_name: listingItem.broker_last_name,
		broker_phone_number: listingItem.broker_phone_number,
		broker_picture_url: listingItem.broker_picture_url,
		primary_seller: listingItem.primary_seller,
		lot_numbers_full: listingItem.lot_numbers_full,
		all_owners_names: listingItem.all_owners_names,
	}

	if (listingItem.launch_date) {
		newListing.launch_date = new Date(listingItem.launch_date)
		newListing.launch_date_ms = newListing.launch_date.getTime()
	} else {
		newListing.launch_date_ms = 0
	}

	if (Number.isNaN(newListing.pricePerSqft)) {
		newListing.pricePerSqft = null
	}

	if (Number.isNaN(newListing.pricePerConstructibleSqft)) {
		newListing.pricePerConstructibleSqft = null
	}

	if (newListing.type === LayerType.landerz && !newListing.link) {
		console.warn(`Listing id:${listingItem.id} hubspot_id:${listingItem.hubspot_record_id} is missing a link`)
		Sentry.captureMessage(`Listing id:${listingItem.id} hubspot_id:${listingItem.hubspot_record_id} is missing a link`)
		newListing.link = "https://en.landerz.ca/listings/pagenotfound"
	}

	// validate the listing based on the zod schema
	let validatedListing = ListingSchema.safeParse(newListing)

	// todo: move this validtion on the server side
	if (newListing.latitude == 0 || newListing.longitude == 0) {
		validatedListing.success = false
	}

	if (!validatedListing.success) {
		console.error("Error while parsing listing received from the API:", newListing)

		Sentry.captureMessage("Error while parsing listing received from the API: " + newListing.id)

		console.error(validatedListing.error)
		return undefined
	} else {
		return validatedListing.data
	}
}

export async function getListings(apiToken: string): Promise<Array<Listing>> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const response = await axios.post<{ data: { listings: Listing[] } }>(
		endpoint,
		{
			query: `query Listings {
				listings {
					analysis_buildable_area
					analysis_buildable_area_price_per_sqft
					analysis_land_area
					analysis_land_area_price_per_sqft
					broker_email
					broker_first_name
					broker_last_name
					broker_phone_number
					broker_picture_url
					call_for_bids
					development_type
					hubspot_record_id
					id
					latitude
					launch_date
					listing_asking_price
					listing_expiration_date
					listing_type
					listing_webpage_url
					longitude
					lot_address
					lot_image_url
					lot_postal_code
					lot_state
					lot_city
					off_market_radius
					sales_process_status
					lot_numbers
					lot_numbers_full
					all_owners_ids
					all_owners_names
					primary_seller
				}
			}`,
		},
		{
			headers: {
				"x-api-token": apiToken,
			},
		}
	)

	try {
		const rawListings: Listing[] = response.data.data.listings

		const validatedListings: Listing[] = []

		for (let rawListing of rawListings) {
			let validatedListing = processListingData(rawListing)
			if (validatedListing) {
				validatedListings.push(validatedListing)
			}
		}
		return validatedListings
	} catch (error: any) {
		throw new Error(error)
	}
}

export async function getMunicipalities(): Promise<Municipality[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	try {
		// prettier-ignore
		const response = await axios.post<{ data: { municipalities: Municipality[] } }>(
            endpoint,
            {
                query: `query GetMunicipalities {
                    municipalities {
                        name
                    }
                }`
            }
        )

		// Data mapping
		const municipalities: Municipality[] = response.data.data.municipalities.map(municipality => ({
			name: municipality["name"],
		}))

		// Validation
		const validMunicipalities = municipalities.filter(municipality => {
			const validated = MunicipalitySchema.safeParse(municipality)

			if (!validated.success) {
				console.error("Invalid object data:", validated.error.issues)
				return false
			}
			return true
		})

		return validMunicipalities
	} catch (error: unknown) {
		throw new Error(`Error fetching municipalities: ${(error as Error).message}`)
	}
}

const transactionQueryData = `
    presentationID
    parcels {
      parcelID
      isActive
    }
    contacts {
      id
      firstName
      lastName
      address
      email
      primaryCompany {
          id
          name
          address
          domain
          isMoral
          neq
          type
          activity
      }
    }
    deedOfSaleDate
    seller {
      id
      name
      adresse
      isMoral
      neq
      type
      activity
    }
    buyer {
      id
      name
      adresse
      isMoral
      neq
      type
      activity
    }
    location {
      coordinates {
        latitude
        longitude
      }
      address
      municipality
      borough
    }
    superficy {
      squareMeters
      squareFeet
    }
    id
    inscriptionNumber
    date
    psaDate
    type
    salePrice
    dataRoomLink
    comments
    address
    city
    postalCode
    saleContext
    broker
    agency
    marketplace
    marketplaceId
    mortgage
    lender
    lenderAmount
    pricePerLandSqft
    pricePerDevelopableSqft
    pricePerBuildableSqft
    pricePerUnit
    landSubtype
    authorizedUses
    currentState
    tod
    zoningPlanLink
    zoningGridLink
    urbanPlanLink
    padLink
    otherZoningDocumentLink
    codificationType
    projectApproval
    projectApprovalAtDeedOfSale
    projectDocument
    projectLink
    project3dModelLink
    projectTypes
    stories
    siteCoverageRatio
    density
    buildableAreaSqft
    numberOfUnits
    parkingOutdoorCount
    parkingIndoorCount
    parkingTotal
    parkingRatio
    projectComments
    unitType
    unitAreaSqft
    cptaq
    urbanPerimeter
    infrastructure
    landShape
    parcelReport
    contamination
    contaminationCost
    wetlands
    floodableZone
    endangeredSpecies
    deductionRatio
    deductionComments
    deductionFiles
    developableLandAreaSqft
    infrastructureDeductionRatio
    infrastructureDeductionComments
    netLandAreaSqft
    hasLegacy
    category
    interactiveMapUrl
    planMapUrl
    sateliteMapUrl
    zoningImageUrl
    volumetricImageUrl
    cityInformationSheetUrl
    deedOfSaleUrl
    zoningReportUrl
    metadataStatus
`

export async function getTransactionByPresentationId(apiToken: string, id: number): Promise<Transaction | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query TransactionByPresentationID($id: ID!) {
            transactionByPresentationID(id: $id) {
                ${transactionQueryData}
            }
            }`,
            variables: {
                id: id,
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return null
	}

	// Validation and Data mapping
	const transactionData = response.data.data.transactionByPresentationID
	if (!transactionData) {
		return null
	}

	const result = TransactionSchema.safeParse(transactionData)

	if (!result.success) {
		console.error("Invalid object data:", result.error.issues)
		return null
	}

	return result.data
}

export async function getTransactionsByInscriptionNumber(apiToken: string, num: string): Promise<Transaction[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query TransactionsByInscriptionNumber($inscriptionNumber: String!) {
                transactionsByInscriptionNumber(inscriptionNumber: $inscriptionNumber) {
                    ${transactionQueryData}
                }
            }`,
            variables: {
                inscriptionNumber: num,
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    )

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return []
	}

	// Validation and Data mapping
	const transactions: Transaction[] = response.data.data.transactionsByInscriptionNumber
		.map(tr => {
			const result = TransactionSchema.safeParse(tr)

			if (!result.success) {
				console.error("Invalid object data:", result.error.issues)
				return null
			}
			return result.data
		})
		.filter(Boolean)

	return transactions
}

export async function getTransactions(apiToken: string): Promise<Transaction[]> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query SearchTransactions {
                searchTransactions { ${transactionQueryData} }
            }`,
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    )

	// Validation and Data mapping
	const transactions: Transaction[] = response.data.data.searchTransactions
		.map(tr => {
			const result = TransactionSchema.safeParse(tr)

			if (!result.success) {
				console.error("Invalid object data:", result.error.issues)
				return null
			}
			return result.data
		})
		.filter(Boolean)

	return transactions
}

export async function updateTransaction(
	apiToken: string,
	inscriptionNumber: string,
	presentationId: string,
	overrides: Partial<Transaction>
): Promise<boolean> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const overridesWithMetaInfo = {
		inscriptionNumber,
		presentationId: Number(presentationId),
		...overrides,
	}

	console.debug("transaction overrides", overridesWithMetaInfo)

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `mutation UpdateTransaction($inscriptionNumber: String!, $overrides: TransactionOverrides!) {
                updateTransaction(inscriptionNumber: $inscriptionNumber, overrides: $overrides)
            }`,
            variables: {
                inscriptionNumber,
                overrides: overridesWithMetaInfo,
            },
        },
        {
            headers: {
                'x-api-token': apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return false
	}

	return response.data.data.updateTransaction
}

export async function removeContactFromTransaction(
	apiToken: string,
	inscriptionNumber: string,
	contactId: number
): Promise<boolean> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `mutation DeleteContactTransaction($contactId: ID!, $inscriptionNumber: String!) {
                deleteContactTransaction(contactId: $contactId, inscriptionNumber: $inscriptionNumber)
                }`,
                variables: {
                    "contactId": contactId,
                    "inscriptionNumber": inscriptionNumber,
                }
        },
        {
            headers: {
                'x-api-token': apiToken,
            },
        }
    )

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return false
	}

	return response.data.data.deleteContactTransaction
}

// graphql query to get a parcel by its ID
export async function getParcel(parcelID: number): Promise<Parcel | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const variables = { parcelID }
	const query = `
	query ($parcelID: String!) {
		parcel(parcelID: $parcelID) {
		  parcelID,
		  location {
			coordinates {
			  latitude,
			  longitude
			}
		  }
		}
	  }                       
      `

	try {
		const result = await axios.post(endpoint, {
			query,
			variables,
		})

		const errors = result.data.errors

		if (result.status === 200) {
			if (!errors) {
				let parcelValidation = ParcelSchema.safeParse(result.data.data.parcel)

				if (parcelValidation.success) {
					const parcel = parcelValidation.data
					parcel.location.coordinates.latitude = parseFloat(parcel.location.coordinates.latitude)
					parcel.location.coordinates.longitude = parseFloat(parcel.location.coordinates.longitude)

					return parcel
				} else {
					console.error("Invalid object data:", result)
					return null
				}
			} else {
				console.error(`GraphQL error: ${errors[0].message}`)
				return null
			}
		} else {
			console.error("Error: Parcel not found", result.status)
			return null
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error getParcel id:${parcelID}: ${error}`, "error")
		return null
	}
}

// graphql query to get a prospect by parcel id
export async function getProspect(apiToken: string, parcelID: number): Promise<Prospect | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const variables = { parcelID }
	const query = `
        query Prospect($parcelID: String!) {
            prospect(parcelID: $parcelID) {
                prospectID
                potential
            }
        }
    `

	try {
		const result = await axios.post(
			endpoint,
			{
				query,
				variables,
			},
			{
				headers: {
					"x-api-token": apiToken,
				},
			}
		)

		const errors = result.data.errors

		if (result.status === 200) {
			if (errors) {
				console.error(`GraphQL error: ${errors[0].message}`)
				return null
			}

			const prospect = result.data.data.prospect
			if (!prospect) {
				return null
			}

			const validatedProspect = ProspectSchema.safeParse(prospect)

			if (validatedProspect.success) {
				return validatedProspect.data
			} else {
				console.error("Invalid object data:", result)
				return null
			}
		} else {
			console.error("Error: Prospect not found", result.status)
			return null
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error getProspect id:${parcelID}: ${error}`, "error")
		return null
	}
}

// graphql mutation to update a prospect potential by parcel id
export async function setProspectPotential(
	apiToken: string,
	parcelID: number,
	potential: DevelopmentPotential
): Promise<boolean> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT
	const query = `
        mutation {
            updateProspectPotential(parcelID: "${parcelID}", potential: ${potential})
        }
    `

	try {
		const result = await axios.post(
			endpoint,
			{
				query,
			},
			{
				headers: {
					"x-api-token": apiToken,
				},
			}
		)

		if (result.status === 200) {
			const errors = result.data.errors

			if (errors) {
				console.error(`GraphQL error: ${errors[0].message}`)
				return false
			}
			return true
		} else {
			console.error("Error: could not update prospect potential", result.status)
			return false
		}
	} catch (error: any) {
		console.error("Error.", error)
		Sentry.captureMessage(`Network error setProspectPotential id:${parcelID}: ${error}`, "error")
		return false
	}
}

/** Login with the provided credentials and get a api token */
export async function getApiToken(username: string, password: string) {
	const endpoint = import.meta.env.VITE_AUTH_API_ENDPOINT
	const payload = {
		data: {
			username: username,
			password: password,
		},
	}
	try {
		const result = await axios.post(endpoint + "login", payload)
		const token = result.data.data.token
		return token
	} catch (error: any) {
		throw new Error(error)
	}
}

export async function getShapefile(apiToken: string, parcelID: string): Promise<[string, string]> {
	const endpoint = import.meta.env.VITE_GEOM_API_ENDPOINT
	const filename = `parcel-${parcelID}-shapefile.zip`
	const url = urljoin(endpoint, filename)
	try {
		const response = await axios.get(url.toString(), {
			headers: {
				"x-api-token": apiToken,
			},
			responseType: "blob",
		})

		return [response.data, filename]
	} catch (error: any) {
		throw new Error(error)
	}
}

export async function searchContacts(apiToken: string, name: string): Promise<ContactList | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `query SearchContacts($contactName: String!) {
                searchContacts(contactName: $contactName) {
                    id
                    firstName
                    lastName
                    address
                    email
                    primaryCompany {
                        id
                        name
                        address
                        isMoral
                        neq
                        type
                        activity
                    }
                }
            }`,
            variables: {
                contactName: name,
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return null
	}

	// Validation and Data mapping
	const contactData = response.data.data.searchContacts
	if (!contactData) {
		return null
	}

	const result = ContactListSchema.safeParse(contactData)

	if (!result.success) {
		console.error("Invalid object data:", result.error.issues)
		return null
	}

	return result.data
}

// Example API request:
//
//  query SearchCompanies($partialName: String!) {
//    searchCompanies(partialName: $partialName) {
//      id
//      name
//      domain
//      address
//    }
//  }
//
// Example API response:
//
//  {
//    "data": {
//      "searchCompanies": [
//        {
//          "id": "6260495902",
//          "name": "T.G. Beco ltee",
//          "domain": "tgbeco.com",
//          "address": "5967 Rue Jean-Talon"
//        },
//        {
//          "id": "7741883995",
//          "name": "Construction Quali-T",
//          "domain": "constructionquali-t.com",
//          "address": null
//        }
//      ]
//    }
//  }
export async function searchCompanies(apiToken: string, partialName: string): Promise<CompanyList | null> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	const response = await axios.post(
		endpoint,
		{
			query: `query SearchCompanies($partialName: String!) {
                searchCompanies(partialName: $partialName) {
                    id
                    name
                    domain
                    address
                }
            }`,
			variables: {
				partialName,
			},
		},
		{
			headers: {
				"x-api-token": apiToken,
			},
		}
	)

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return null
	}

	// Validation and Data mapping
	const data = response.data.data.searchCompanies
	if (!data) {
		return null
	}

	const result = CompanyListSchema.safeParse(data)

	if (!result.success) {
		console.error("Invalid object data:", result.error.issues)
		return null
	}

	return result.data
}

export async function addContactToTransaction(
	apiToken: string,
	contactId: number,
	role: string,
	inscriptionNumber: string,
	primaryCompanyId: string
): Promise<Contact> {
	const endpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT

	// prettier-ignore
	const response = await axios.post(
        endpoint,
        {
            query: `mutation AddContactTransaction($inscriptionNumber: String!, $contactId: ID!, $role: Role!, $primaryCompanyId: ID) {
              addContactTransaction(inscriptionNumber: $inscriptionNumber, contactId: $contactId, role: $role, primaryCompanyId: $primaryCompanyId) {
                id
                adresse
                address
                firstName
                lastName
                email
                hubspotId
                primaryCompany {
                  id
                  name
                  adresse
                  address
                  isMoral
                  neq
                  type
                  activity
                }
              }
            }`,
            variables: {
                inscriptionNumber,
                contactId: contactId,
                role,
                primaryCompanyId: primaryCompanyId || null,
                source: "app",
            },
        },
        {
            headers: {
                "x-api-token": apiToken,
            },
        }
    );

	if (response.data.errors) {
		response.data.errors.forEach(({ message }) => {
			console.error(message)
		})
		return false
	}

	return response.data.data.addContactTransaction
}
